<!--
 * @Description: What's this for
 * @Autor: zhangzhang
 * @Date: 2021-09-28 17:23:56
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-02-12 12:22:56
-->
<template>
  <div class="manage">
    <div class="manage-body">
      <!-- 广告轮播 -->
      <el-carousel height="240px" direction="vertical" :autoplay="false">
        <el-carousel-item v-for="(item, index) in list" :key="index">
          <div class="advertising" :style="{ background: item.theme }">
            <ul class="flex">
              <li>
                <img class="advertising-image" :src="item.image" />
              </li>
              <li>
                <h2 class="advertising-title">{{ item.title }}</h2>
                <h3 class="advertising-sub">{{ item.subTitle }}</h3>
                <h3 class="advertising-sub">{{ item.text }}</h3>
              </li>
            </ul>
          </div>
        </el-carousel-item>
      </el-carousel>

      <!-- 模板列表 -->
      <models-list></models-list>
    </div>
  </div>
</template>

<script>
import ModelsList from '@/components/ModelsList'

export default {
  name: 'model-manage',
  components: {
    ModelsList
  },

  data() {
    return {
      list: [
        {
          title: '互联网全行业模板',
          subTitle: '模板使用 快速运营 随时获利',
          text: '抢占市场~',
          image: require('../../assets/image/zhibaiCor.png'),
          theme: '#2665ff'
        },
        {
          title: '一键使用模板',
          subTitle: '无需设计 快速上手',
          text: '快速运营~',
          image: require('../../assets/image/zhibaiCor.png'),
          theme: '#F78AE0'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.manage {
  padding: 20px;

  .manage-body {
    width: 100%;
    height: auto;
    padding: 10px;
    background: #fff;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);

    .advertising {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      cursor: pointer;

      .advertising-title {
        margin-top: 40px;
        font-size: 29px;
        font-weight: 550;
        color: #fff;
        letter-spacing: 2px;
      }

      .advertising-sub {
        margin-top: 30px;
        font-size: 18px;
        font-weight: 500;
        color: #fff;
        letter-spacing: 1px;
      }

      .advertising-btn {
        display: inline-block;
        margin-top: 30px;
        padding: 8px 24px;
        border-radius: 2px;
        font-size: 14px;
        background: #fff;
        letter-spacing: 1px;
      }

      .advertising-image {
        width: 120px;
        height: 120px;
        margin-right: 40px;
        margin-top: 30px;
      }
    }
  }
}
</style>
